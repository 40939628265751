import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { media } from 'resources'

import { removeSpace } from 'resources/helpers/format-string'

export const PlanCard = ({
  primaryColor,
  blackDays,
  noMarginMd,
  plan,
  planCount,
  utm,
}) => {
  const listOfFeatures = plan.description ? plan.description.split(/\r?\n/) : []
  const planUrl = `${process.env.GATSBY_CHECKOUT_URL}?plano=${plan.id}&${utm ? utm.replace(/\?/g, '') : plan.utm}`

  const planFormattedPrice = (value) => {
    if (!value) {
      return ''
    }

    const priceSplittedByCents = value.toFixed(2).toString().split('.')
    const priceMainValue = priceSplittedByCents[0]
    const priceCentsValue = priceSplittedByCents[1] || '00'

    return (
      <>
        R$ {priceMainValue}
        <PlanCents hasBlueBackground={hasBlueBackground}>
          ,{priceCentsValue}
        </PlanCents>
      </>
    )
  }

  const getFormattedCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value)
  }

  const renderPlanInstallmentInfo = () => {
    if (plan.numberOfInstallments === 1) {
      return (
        <PlanInstallmentInfo
          hasBlueBackground={hasBlueBackground}
          isBlackDays={blackDays}
          isBestOption={plan.bestOption}
        >
          <span>1x no cartão, boleto{!plan.valueWithPixDiscount && ', ou PIX'}</span>
          {plan.valueWithPixDiscount && (
            <span>
              ou {getFormattedCurrency(plan.valueWithPixDiscount)} à vista (no PIX)
            </span>
          )}
        </PlanInstallmentInfo>
      )
    }

    return (
      <PlanInstallmentInfo
        hasBlueBackground={hasBlueBackground}
        isBlackDays={blackDays}
        isBestOption={plan.bestOption}
      >
        <span>{plan.numberOfInstallments}x sem juros no cartão</span>
        <span>
          {getFormattedCurrency(plan.totalValue)} à vista (cartão, boleto{!plan.valueWithPixDiscount && ', no PIX'})
        </span>
        {plan.valueWithPixDiscount && (
          <span>
            {getFormattedCurrency(plan.valueWithPixDiscount)} à vista (no PIX)
          </span>
        )}
      </PlanInstallmentInfo>
    )
  }

  const hasBlueBackground = planCount > 2 && !plan.bestOption

  const renderPlanTitle = (accessTimeInMonths) => {
    if (accessTimeInMonths > 12) {
      return (
        <PlanTitle isBlackDays={blackDays}>
          12 MESES DE ACESSO + {accessTimeInMonths - 12} {accessTimeInMonths - 12 === 1 ? 'MÊS' : 'MESES'} DE BÔNUS
        </PlanTitle>
      )
    }
    if (accessTimeInMonths === 2) {
      return (
        <PlanTitle isBlackDays={blackDays}>
          1 MÊS DE ACESSO + 1 MÊS DE BÔNUS
        </PlanTitle>
      )
    }
  }

  return (
    <Wrapper
      primaryColor={primaryColor}
      isBlackDays={blackDays}
      noMarginMd={noMarginMd}
      isBestOption={plan.bestOption}
      planCount={planCount}
      hasBlueBackground={hasBlueBackground}
    >
      <Title
        isBestOption={plan.bestOption}
        hasBlueBackground={hasBlueBackground}
        isBlackDays={blackDays}
      >
        <h3>
          {plan.accessTimeInMonths === 1
            ? '1 MÊS DE ACESSO'
            : `${plan.accessTimeInMonths} MESES DE ACESSO`}
        </h3>
        {renderPlanTitle(plan.accessTimeInMonths)}
        <PlanSubtitle
          hasBlueBackground={hasBlueBackground}
          isBestOption={plan.bestOption}
          isBlackDays={blackDays}
        >
          COM CORREÇÃO DE REDAÇÃO
        </PlanSubtitle>
      </Title>

      {plan.bestOption && <Ribbon isBlackDays={blackDays}>Recomendado</Ribbon>}

      <PlanPriceWrapper
        hasBlueBackground={hasBlueBackground}
        isBestOption={plan.bestOption}
        isBlackDays={blackDays}
      >
        <PlanPrice hasBlueBackground={hasBlueBackground}>
          {planFormattedPrice(plan.valueOfInstallment)}
        </PlanPrice>

        {renderPlanInstallmentInfo()}
      </PlanPriceWrapper>

      {listOfFeatures.length > 0 && (
        <List>
          {listOfFeatures.map((item, index) => (
            <ListItem
              key={index}
              dangerouslySetInnerHTML={{ __html: item }}
              hasBlueBackground={hasBlueBackground}
              isBlackDays={blackDays}
              isBestOption={plan.bestOption}
            />
          ))}
        </List>
      )}

      {plan.bestOption ? (
        <RecommendedPlanSubscribeButton
          href={`${planUrl}&field_action=${removeSpace(plan.name)}`}
          hasBlueBackground={hasBlueBackground}
          isBlackDays={blackDays}
        >
          QUERO ESTE
        </RecommendedPlanSubscribeButton>
      ) : (
        <NormalPlanSubscribeButton
          href={`${planUrl}&field_action=${removeSpace(plan.name)}`}
          hasBlueBackground={hasBlueBackground}
          isBlackDays={blackDays}
        >
          QUERO ESTE
        </NormalPlanSubscribeButton>
      )}
    </Wrapper>
  )
}

PlanCard.propTypes = {
  primaryColor: t.string,
  blackDays: t.bool,
  noMarginMd: t.bool,
  plan: t.object,
  planCount: t.number,
  utm: t.string,
}

const PlanTitle = styled.span`
  background: ${({ theme, isBlackDays }) => isBlackDays ? theme.blackFridayTheme.bodyBackground : theme.colors.primary};
  padding: 4px;
  border-radius: 4px;
  font-weight: 900;
  font-size: 1rem;
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 3rem 2.5rem;

  background: ${({
    theme,
    primaryColor,
    isBlackDays,
    hasBlueBackground,
    isBestOption,
  }) => {
    if (isBlackDays) {
      return isBestOption
        ? theme.blackFridayTheme.primary
        : theme.blackFridayTheme.bodyBackground
    }

    if (hasBlueBackground) {
      return 'linear-gradient(180deg, rgba(50,64,126,1) 0%, rgba(0,17,94,1) 100%);'
    }

    return primaryColor || theme.colors.background
  }};

  border: 1px solid
    ${({ theme, hasBlueBackground }) =>
      hasBlueBackground ? theme.colors.muted : 'none'};
  border-radius: ${({ theme }) => theme.radius.lg};

  height: 0%;
  max-width: 34rem;

  order: ${({ isBestOption, planCount }) =>
    isBestOption && planCount >= 3 ? 1 : 0};

  transform: ${({ isBestOption }) =>
    isBestOption ? 'scale(1.05)' : 'scale(1)'};

  ${({ noMarginMd }) =>
    noMarginMd &&
    media.between('sm', 'lg')`
    margin-right: 0;
  `}

  &:last-child {
    order: 2;
  }

  ${media.lessThan('md')`
    max-width: 70%;
    margin: 0 auto;

    &:not(:last-child) {
      margin-bottom: ${({ isBestOption }) => (isBestOption ? '5rem' : '3rem')};
    }
  `}

  ${media.lessThan('sm')`
    margin: 0 .5rem;
    max-width: 100%;
  `}
`

const Ribbon = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0.75rem 0;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme, isBlackDays }) =>
    isBlackDays ? theme.blackFridayTheme.primary : theme.colors.primary};

  background: rgb(0, 235, 169);
  background: ${({ theme, isBlackDays }) => {
    if (isBlackDays) {
      return theme.blackFridayTheme.bodyBackground
    }

    return `
      radial-gradient(
        circle,
        rgba(0, 235, 169, 1) 0%,
        rgba(0, 232, 170, 1) 26%,
        rgba(0, 153, 213, 1) 99%,
        rgba(0, 73, 255, 1) 100%
      )
    `
  }};
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  gap: 1rem;
  text-align: center;

  > * {
    color: ${({ theme, isBlackDays, hasBlueBackground, isBestOption }) => {
      if (isBlackDays) {
        return isBestOption
          ? theme.blackFridayTheme.textDark
          : theme.blackFridayTheme.text
      }

      return hasBlueBackground ? 'white' : theme.colors.primary
    }};
  }

  > h4 {
    font-size: 2.4rem;
  }

  > h3 {
    font-size: 2.8rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  > h2 {
    font-size: 1.8rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  > span {
    color: ${({ theme, isBlackDays }) => isBlackDays ? theme.blackFridayTheme.textYellow : theme.colors.background};
  }
`

const PlanSubtitle = styled.div`
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
  color: ${({ theme, isBlackDays, hasBlueBackground, isBestOption }) => {
    if (isBlackDays) {
      return isBestOption
        ? theme.blackFridayTheme.textDark
        : theme.blackFridayTheme.primary
    }

    return hasBlueBackground ? theme.colors.accent : theme.colors.primary
  }};
`

const PlanPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 0.25rem;
  margin-top: ${({ isBestOption }) => (isBestOption ? '4rem' : '0')};

  > * {
    color: ${({ theme, isBlackDays, hasBlueBackground, isBestOption }) => {
      if (isBlackDays) {
        return isBestOption
          ? theme.blackFridayTheme.textDark
          : theme.blackFridayTheme.text
      }

      return hasBlueBackground ? 'white' : theme.colors.primary
    }};
  }
`

const PlanPrice = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: center;
  font-size: 56px;

  ${media.lessThan('xsm')`
    font-size: 45px;
  `}
`

const PlanCents = styled.div`
  font: normal normal 900 30px/70px Gilroy;
  letter-spacing: 0px;
  line-height: normal;
`

const PlanInstallmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-weight: bold;

  color: ${({ theme, isBlackDays, hasBlueBackground, isBestOption }) => {
    if (isBlackDays) {
      return isBestOption
        ? theme.blackFridayTheme.textDark
        : theme.blackFridayTheme.textYellow
    }

    return hasBlueBackground ? theme.colors.accent : theme.colors.primary
  }};
`

const List = styled.ul`
  padding: 2rem 0 0 0;
  list-style: none;
`

const ListItem = styled.li`
  position: relative;
  padding-left: 2.5rem;
  font-size: 1.2rem;

  color: ${({ theme, isBlackDays, isBestOption, hasBlueBackground }) => {
    if (isBlackDays) {
      return isBestOption
        ? theme.blackFridayTheme.textDark
        : theme.blackFridayTheme.text
    }

    return hasBlueBackground ? 'white' : theme.colors.third
  }};

  &:not(:last-child) {
    padding-bottom: 1.8rem;
  }

  &:before {
    content: ${({ hasBlueBackground, isBlackDays, isBestOption }) => {
      if (isBlackDays) {
        return isBestOption
          ? 'url("ic-check-black.svg")'
          : 'url("ic-check-yellow.svg")'
      }

      return hasBlueBackground
        ? 'url("ic-check-green.svg")'
        : 'url("ic-check-blue.svg")'
    }};
    position: absolute;
    left: 0;
    top: -0.2rem;
  }
`

const RecommendedPlanSubscribeButton = styled.a`
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  border-radius: 38px;
  padding: 2rem 0;
  margin-top: 2rem;
  font: normal normal 800 20px/25px Gilroy;
  letter-spacing: 1.6px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 200ms;

  color: ${({ theme, isBlackDays }) =>
    isBlackDays ? theme.blackFridayTheme.textYellow : theme.colors.primary};

  background-color: ${({ theme, isBlackDays }) =>
    isBlackDays ? theme.blackFridayTheme.textDark : theme.colors.accent};

  &:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`

const NormalPlanSubscribeButton = styled.a`
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  padding: 2rem 0;
  margin-top: 2rem;
  font: normal normal 800 20px/25px Gilroy;
  letter-spacing: 1.6px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 200ms;

  border-radius: 38px;
  border: 2px solid
    ${({ theme, hasBlueBackground, isBlackDays }) => {
      if (isBlackDays) {
        return theme.blackFridayTheme.primary
      }

      return hasBlueBackground ? 'white' : theme.colors.primary
    }};

  background: ${({ hasBlueBackground }) =>
    hasBlueBackground ? 'transparent' : '#f7f7f7'};

  color: ${({ theme, hasBlueBackground }) =>
    hasBlueBackground ? 'white' : theme.colors.primary};

  &:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`
