import { HorizontalDivider as BaseHorizontalDivider } from 'components/UI/HorizontalDivider'
import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { media, theme } from 'resources'

export const TestimonialCard = ({
  data,
  isBlackFriday,
  isHomePage,
  equalHeights,
}) => {
  return (
    <Card
      isBlackFriday={isBlackFriday}
      isHomePage={isHomePage}
      equalHeights={equalHeights}
    >
      <TestimonialContent isBlackFriday={isBlackFriday} isHomePage={isHomePage}>
        {data.content}
      </TestimonialContent>
      <Footer>
        <HorizontalDivider isBlackFriday={isBlackFriday} />
        <StudentWrapper>
          <StudentPicture>
            <img src={data.image} alt={data.name} />
          </StudentPicture>
          <StudentInfo isBlackFriday={isBlackFriday} isHomePage={isHomePage}>
            <span>{data.name}</span>
            <b>{data.description}</b>
          </StudentInfo>
        </StudentWrapper>
      </Footer>
    </Card>
  )
}

TestimonialCard.propTypes = {
  data: t.object.isRequired,
  isBlackFriday: t.bool,
  isHomePage: t.bool,
  equalHeights: t.bool,
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${({ equalHeights }) => (equalHeights ? '425px' : '200px')};
  max-height: 425px;
  gap: 2rem;
  padding: 2.5rem;
  line-height: 1.5rem;
  font-size: 1.2em;

  box-shadow: 0px 3px 4px #000d2e12;
  border-radius: 16px;
  border: ${({ isHomePage, isBlackFriday }) =>
    isHomePage && isBlackFriday ? 'unset' : '1px solid #d6e1ff'};

  color: ${({ isBlackFriday }) =>
    isBlackFriday ? theme.blackFridayTheme.text : 'black'};

  background-color: ${({ isBlackFriday }) =>
    isBlackFriday ? theme.blackFridayTheme.bodyBackground : 'white'};

  ${media.lessThan('xsm')`
    max-height: 100%;
  `}
`

const TestimonialContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 1.2em;
  letter-spacing: 0;
  line-height: 24px;
  color: ${({ isHomePage, isBlackFriday, theme }) =>
    isHomePage && isBlackFriday ? theme.blackFridayTheme.text : '#385395'};
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const StudentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const StudentPicture = styled.div`
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  color: ${({ isHomePage, isBlackFriday, theme }) =>
    isHomePage && isBlackFriday ? theme.blackFridayTheme.text : '#385395'};

  span {
    font-weight: 600;
    font-size: 1.5em;
  }

  b {
    font-weight: bold;
    font-size: 1.3em;
    color: ${({ theme, isBlackFriday }) =>
      isBlackFriday ? theme.blackFridayTheme.primary : theme.colors.primary};
  }
`

const HorizontalDivider = styled(BaseHorizontalDivider)`
  background-color: ${({ theme, isBlackFriday }) =>
    isBlackFriday ? theme.blackFridayTheme.divider : theme.colors.divider};
`
