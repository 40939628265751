import React, { useContext } from 'react'
import {
  MdOutlineChevronRight,
  MdOutlineClose,
  MdOutlineLogin as BaseMdOutlineLogin,
} from 'react-icons/md'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { NavbarExternalLink } from 'components/UI/NavbarLink'
import { media, useWindowSize } from 'resources'
import { HorizontalDivider as BaseHorizontalDivider } from 'components/UI/HorizontalDivider'
import { CONTACT_EMAIL, SUBSCRIBE_URL, FREE_COURSE_URL } from 'resources/constants'
import { Social } from 'components/social/social'
import BlackFridayContext from 'resources/contexts/BlackFridayContext'
import t from 'prop-types'

const links = [
  {
    label: 'MURAL DOS APROVADOS',
    to: '/mural-aprovados',
  },
  {
    label: 'ASSINE',
    to: SUBSCRIBE_URL,
    isExternalLink: true,
  },
  {
    label: 'CURSOS GRATUITOS',
    to: `${FREE_COURSE_URL}?field_action=cadastro_gratuito_header`,
    isExternalLink: true,
  },
]

export const DrawerMenu = ({ isOpen, setIsDrawerMenuOpen }) => {
  const isBlackFriday = useContext(BlackFridayContext)
  const { width } = useWindowSize()

  const renderLink = (link, linkIndex) => {
    if (link.isExternalLink) {
      return (
        <ExternalLink
          key={linkIndex}
          href={link.to}
          target='_blank'
          rel='noopener noreferrer'
        >
          <LinkArrow /> {link.label}
        </ExternalLink>
      )
    }

    return (
      <Link key={linkIndex} to={link.to}>
        <LinkArrow /> {link.label}
      </Link>
    )
  }

  React.useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  return (
    <Wrapper isOpen={isOpen} isBlackFriday={isBlackFriday}>
      <TopActions>
        <CloseDrawerIconWrapper onClick={() => setIsDrawerMenuOpen(false)}>
          <CloseDrawerIcon />
        </CloseDrawerIconWrapper>

        <LogoWrapper>
          <Logo>
            <Link to='/'>
              <img src='/logo-white.svg' alt='Professor Ferretto' />
            </Link>
          </Logo>
        </LogoWrapper>

        <div
          style={{
            fontSize: '1.2em',
            flexGrow: 1,
            flexBasis: 0,
            justifyContent: 'flex-end',
          }}
        >
          <LoginLink
            href={`${process.env.GATSBY_WEB_URL}/login`}
            size='default'
            variant='ghost'
            className='gap-2'
            isStickyHeader
          >
            Já sou
            <br />
            aluno
            <MdOutlineLogin />
          </LoginLink>
        </div>
      </TopActions>

      <HorizontalDivider />

      <Content>
        <LinksWrapper>
          {links.map((link, linkIndex) => renderLink(link, linkIndex))}
        </LinksWrapper>
        <BottomInfo>
          <SectionContent>
            <SectionTitle>ESCREVA PRA GENTE!</SectionTitle>
            <MailLink href={`mailto:${CONTACT_EMAIL}`}>
              {CONTACT_EMAIL}
            </MailLink>
          </SectionContent>
          <SectionContent>
            <SectionTitle>REDES SOCIAIS</SectionTitle>
            <Social iconSize={width <= 300 ? 3.5 : 5} align='flex-start' />
          </SectionContent>
        </BottomInfo>
      </Content>
    </Wrapper>
  )
}

DrawerMenu.propTypes = {
  isOpen: t.bool.isRequired,
  setIsDrawerMenuOpen: t.func.isRequired,
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-200%')};
  width: 100vw;
  height: 100vh;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  font-weight: bold;
  transition: all 500ms ease-in-out;
  z-index: 10;

  background-color: ${({ theme, isBlackFriday }) =>
    isBlackFriday ? '#141414' : theme.colors.primary};

  ${media.greaterThan('md')`
    display: none;
  `}

  ${media.lessThan('xsm')`
    gap: 2rem;
  `}
`

const TopActions = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;

  ${media.lessThan('xsm')`
    gap: 2rem;
  `}
`

const HorizontalDivider = styled(BaseHorizontalDivider)`
  background-color: rgba(138, 138, 138, 0.5);
`

const CloseDrawerIconWrapper = styled.div`
  flex: 1;
  cursor: pointer;
`

const CloseDrawerIcon = styled(MdOutlineClose)`
  color: white;
  font-size: 4em;

  ${media.lessThan('xsm')`
    font-size: 3em;
  `}
`

const LogoWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`

const Logo = styled.h1`
  pointer-events: auto;

  img {
    height: 40px;
  }

  ${media.lessThan('xsm')`
    img {
      height: 25px;
    }
  `}
`

const Link = styled((props) => <GatsbyLink {...props} />)`
  display: flex;
  align-items: center;
  font-size: 2em;
  color: white;
  text-decoration: none;

  ${media.lessThan('xsm')`
    font-size: 1.5em;
  `}
`

const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 2em;
  color: white;
  text-decoration: none;

  ${media.lessThan('xsm')`
    font-size: 1.5em;
  `}
`

const LoginLink = styled(NavbarExternalLink)`
  flex: 1;
  font-size: 1em;
  color: white !important;
  justify-content: flex-end;

  ${media.lessThan('xsm')`
    font-size: 0.7em;
  `}
`

const MdOutlineLogin = styled(BaseMdOutlineLogin)`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 2.5rem;

  ${media.lessThan('xsm')`
    font-size: 1.5rem;
  `}
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`

const LinkArrow = styled(MdOutlineChevronRight)`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 1.5em;
  margin-right: 1rem;
`

const BottomInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  font-size: 1.5em;
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const SectionTitle = styled.span`
  color: ${({ theme }) => theme.colors.accent};
`

const MailLink = styled.a`
  color: white;
  text-decoration: none;
`

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 2rem 10rem;

  ${media.lessThan('xsm')`
    padding: 0 0 7rem;
  `}
`
