import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { Container as BaseContainer } from 'components'
import { media, theme } from 'resources'
import { NavbarExternalLink } from 'components/UI/NavbarLink'
import { MdOutlineLogin, MdOutlineMenu } from 'react-icons/md'
import { SUBSCRIBE_URL, FREE_COURSE_URL } from 'resources/constants'
import { DrawerMenu } from 'components/drawer-menu/drawer-menu'
import BlackFridayContext from 'resources/contexts/BlackFridayContext'
import t from 'prop-types'

const NAVBAR_SCROLL_LIMIT = 800

export const Header = ({ utm }) => {
  const isBlackFriday = useContext(BlackFridayContext)
  const [isDrawerMenuOpen, setIsDrawerMenuOpen] = React.useState(false)
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  const logoFileName =
    scrollPosition >= NAVBAR_SCROLL_LIMIT ? isBlackFriday ? '/logo-white.svg' : '/logo-blue.svg' : '/logo-white.svg'

  const isSticky = scrollPosition >= NAVBAR_SCROLL_LIMIT

  React.useEffect(() => {
    if (isDrawerMenuOpen && !isSticky) {
      window.scrollTo(0, 0)
    }
  }, [isDrawerMenuOpen, isSticky])

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Box isBlackFriday={isBlackFriday} isStickyHeader={isSticky}>
      <DrawerMenu
        isOpen={isDrawerMenuOpen}
        setIsDrawerMenuOpen={setIsDrawerMenuOpen}
      />

      <Container>
        <Wrapper>
          <DrawerIconWrapper
            isStickyHeader={isSticky}
            onClick={() => setIsDrawerMenuOpen(true)}
          >
            <MdOutlineMenu />
          </DrawerIconWrapper>

          <Logo>
            <Link to='/'>
              <img src={logoFileName} alt='Professor Ferretto' />
            </Link>
          </Logo>

          <MobileLoginLink
            href={`${process.env.GATSBY_WEB_URL}/login${utm}`}
            size='default'
            variant='ghost'
            className='gap-2'
            isStickyHeader={isSticky}
          >
            Já sou
            <br />
            aluno
            <MdOutlineLogin style={{ fontSize: '2.5rem' }} />
          </MobileLoginLink>

          <Actions>
            <SubscribeButton
              isBlackFriday={isBlackFriday}
              isStickyHeader={isSticky}
              href={`${process.env.GATSBY_SITE_URL}/mural-aprovados`}
              mural
            >
              MURAL DOS APROVADOS
            </SubscribeButton>
            <NavbarExternalLink
              href={`${FREE_COURSE_URL}${utm ? `${utm}&` : '?'}field_action=cadastro_gratuito_header`}
              size='default'
              variant='default'
              isStickyHeader={isSticky}
              isBlackFriday={isBlackFriday}
            >
              Cursos gratuitos
            </NavbarExternalLink>
            <SubscribeButton href={`${SUBSCRIBE_URL}${utm ? utm.replace(/\?/g, '') : ''}&field_action=assine`} isStickyHeader={isSticky} isBlackFriday={isBlackFriday}>
              ASSINE
            </SubscribeButton>
            <NavbarExternalLink
              href={`${process.env.GATSBY_WEB_URL}/login${utm ? `${utm}&` : '?'}field_action=acesso_aluno`}
              size='default'
              variant='ghost'
              className='gap-2'
              isStickyHeader={isSticky}
              isBlackFriday={isBlackFriday}
            >
              Já sou aluno
              <MdOutlineLogin style={{ fontSize: '2rem' }} />
            </NavbarExternalLink>
          </Actions>
        </Wrapper>
      </Container>
    </Box>
  )
}

const Box = styled.section`
  width: 100%;
  position: ${({ isStickyHeader }) => (isStickyHeader ? 'fixed' : 'block')};
  top: 0;
  transition: all 100ms;
  z-index: 10;

  background-color: ${({ isBlackFriday, isStickyHeader }) =>
    isStickyHeader
      ? isBlackFriday ? theme.blackFridayTheme.background : 'white'
      : isBlackFriday
      ? '#141414'
      : '#00115e'} !important;

  box-shadow: ${({ isStickyHeader }) =>
    isStickyHeader ? 'rgba(0, 0, 0, 0.1) 0px 4px 12px;' : 'none'};
`

const Container = styled(BaseContainer)`
  ${media.lessThan('xxsm')`
    padding: 0 0.5rem;
  `}
`

const SubscribeButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isStickyHeader, isBlackFriday, mural }) => {
    if (!isBlackFriday) {
      return `${!mural && 'border: 1px solid'};
      color: ${isStickyHeader ? theme.colors.primary : 'white'};`
     }
    return `
      border: 1px solid ${mural ? theme.blackFridayTheme.primary : 'white'};
      color: ${mural ? theme.blackFridayTheme.primary : 'white'};`
  }}

  border-radius: 5rem;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5em;
  padding: ${({ mural }) => mural ? '0 3rem' : '0 6rem'};
  transition: all 250ms;
  text-decoration: none;

  &:hover {
    background-color: ${({ isStickyHeader, isBlackFriday }) =>
      isStickyHeader && !isBlackFriday
        ? theme.colors.primary
        : 'white'} !important;
    color: ${({ isStickyHeader, isBlackFriday }) =>
      isStickyHeader && !isBlackFriday ? 'white' : '#001781'} !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0;

  ${media.lessThan('sm')`
    padding: 1.5rem 0.5rem;
  `}

  ${media.greaterThan('md')`
    padding: 1.2rem 4.5rem;
  `}

  ${media.greaterThan('xxl')`
    padding: 1.2rem 4.5rem;
  `}
`

const Logo = styled.h1`
  margin-right: 2rem;
  max-width: 20rem;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }

  ${media.lessThan('sm')`
    max-width: 15rem;
    margin-right: 0;
  `}

  ${media.lessThan('xsm')`
    max-width: 11rem;
    margin-right: 0;
  `}
`

const Link = styled((props) => <GatsbyLink {...props} />)`
  display: flex;
  align-items: center;
`

const Actions = styled.div`
  display: flex;
  gap: 2.5rem;

  ${media.lessThan('sm')`
    margin-top: 1.5rem;
  `}

  ${media.lessThan('md')`
    display: none;
  `}

  ${media.lessThan('xsm')`
    > a:first-child {
      margin-left: 0;
    }
  `}

  > *:last-child {
    ${media.lessThan('smd')`
      display: none;
    `}
  }
`

const DrawerIconWrapper = styled.div`
  color: ${({ theme, isStickyHeader }) =>
    isStickyHeader ? theme.colors.primary : 'white'};

  font-size: 3em;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 0;

  ${media.greaterThan('md')`
    display: none;
  `}
`

const MobileLoginLink = styled(NavbarExternalLink)`
  color: ${({ theme, isStickyHeader }) =>
    isStickyHeader ? theme.colors.primary : 'white'};

  justify-content: flex-end;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 1.2em;

  ${media.greaterThan('md')`
    display: none;
  `}

  ${media.lessThan('xsm')`
    font-size: 0.7em;
  `}
`
Header.propTypes = {
  utm: t.string,
}
