import React from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { Container, Text as BaseText } from 'components'

import { media, theme } from 'resources'

export const LeadCapture = ({ border, utm }) => {
  return (
    <Wrapper border={border}>
      <Box>
        <Content>
          <TeacherPictureWrapper>
            <img src='/footer-teacher.png' alt='Professor Landim' />
          </TeacherPictureWrapper>

          <TextContent>
            <Text align='left'>
              <b>
                Cadastre-se{' '}
                <span style={{ color: theme.colors.accent }}>
                  gratuitamente
                </span>{' '}
                e receba cursos e materiais completos de alto nível
              </b>
            </Text>
          </TextContent>

          <SubscribeLink
            href={`https://curso-gratuito.professorferretto.com.br${utm ? `${utm}&` : '?'}field_action=cadastro_gratuito_header`}
          >
            <span>CADASTRE-SE</span>
          </SubscribeLink>
        </Content>
      </Box>
    </Wrapper>
  )
}

LeadCapture.propTypes = {
  border: t.bool,
  utm: t.string,
}

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  background-color: #0049ff;
  min-height: 32rem;

  ${({ border }) =>
    border &&
    css`
      border-top: 1px solid rgba(230, 230, 230, 0.7);
    `};

  ${media.lessThan('md')`
    min-height: 0rem;
    padding: 6rem 1rem;
  `}
`

const Box = styled(Container)`
  max-width: 120rem;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${media.lessThan('md')`
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
  `}
`

const TextContent = styled.div`
  flex: 1;
  padding: 0 5rem;

  ${media.lessThan('md')`
    padding: 0;

    & > * {
      text-align: center;
    }
  `}
`

const SubscribeLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  background-color: white;
  padding: 2rem 10rem;
  border: 0;
  border-radius: ${({ theme }) => theme.radius.xl};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.7rem;
  font-weight: bold;
  text-decoration: none;
  transition: box-shadow 0.2s ease;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  ${media.lessThan('sm')`
    padding: 2rem 8rem;
  `}

  ${media.lessThan('xsm')`
    padding: 2rem 5rem;
  `}
`

const TeacherPictureWrapper = styled.div`
  margin-bottom: -3px;

  img {
    margin-top: -45px;
  }

  ${media.lessThan('md')`
    display: none;
  `}
`

const Text = styled(BaseText)`
  color: white;
  font-size: 2.5rem;

  ${media.lessThan('md')`
    font-size: 2rem;
  `}
`
